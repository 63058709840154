@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
html {
  scroll-behavior: smooth;
}

@import url('https://fonts.cdnfonts.com/css/uber-move-text');


h1, h2, h3, h4, h5, h6, p, a, li, ul, input, button, textarea, input::placeholder {
  font-family: 'Uber Move', sans-serif !important;
}

textarea:focus, input:focus, button, button:focus{
  outline: none;
}